<template>
    <div class="mb-3">
        <b-field>
            <b-select v-model="selectedProfileTypeId" placeholder="" @input="handleTypeSelected" size="is-small">
                <option :value="null">{{$t('show all')}}</option>
                <option v-for="profileType in profileTypes"
                        :value="profileType.id"
                        :key="'artistic-profile-type-' + profileType.id">
                    {{ $t(profileType.name) }}
                </option>
            </b-select>
        </b-field>
    </div>
</template>

<script>
import {GET_ARTISTIC_PROFILE_TYPES} from "@/store/modules/artisticProfileTypes/action-types";
import {mapActions} from "vuex";
import {GET_FAVORITE_ARTISTIC_PROFILES_COLLECTION} from "@/store/modules/artisticProfiles/actions-types";

export default {
    name: "ArtisticProfileCollectionListFilter",
    data(){
        return {
            selectedProfileTypeId: null,
        };
    },
    methods: {
        ...mapActions('artisticProfileTypes', [GET_ARTISTIC_PROFILE_TYPES]),
        ...mapActions('artisticProfiles', [GET_FAVORITE_ARTISTIC_PROFILES_COLLECTION]),
        handleTypeSelected(){
            this.$Progress.start();
            this[GET_FAVORITE_ARTISTIC_PROFILES_COLLECTION]({
                params: { 'filter[type_id]': this.selectedProfileTypeId}
            }).then(() => {
                this.$Progress.finish();
            });
        },
    },
    created() {
        this[GET_ARTISTIC_PROFILE_TYPES]();
    },
    computed:{
        profileTypes(){
            return this.$store.state.artisticProfileTypes.types;
        },
    },
};
</script>

<style scoped>

</style>