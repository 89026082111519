<template>
    <div class="card mb-3"
         :key="'artistic-profile-' + artisticProfile.id">

        <!-- GALLERY / LOGO -->
        <b-image v-if="artisticProfile.images && Object.keys(artisticProfile.images).length"
                 ratio="4by3"
                 responsive
                 :src="artisticProfile.images[Object.keys(artisticProfile.images)[0]].original_url"
                 alt="artistic profile logo"></b-image>

        <div class="card-header">
            <!--CANDIDATE PROFILE NAME-->
            <div class="card-header-title">
                {{artisticProfile.title}}
            </div>

            <!-- MARK AS FAVORITE -->
<!--            <ProfileFavoriteCollectionToggle :profile="artisticProfile" :application="application"/>-->

        </div>

        <div class="card-content px-2">

            <!--TYPE-->
            <div class="block" v-if="artisticProfile.type">
                <span class="heading mb-3">{{$t('profile type')}}</span>
                {{$t(artisticProfile.type.name) }}
            </div>

            <b-collapse :open="showDetails" class="mb-3">
                <!--LINKS-->
                <div class="block" v-if="profileHasLinks">
                    <span class="heading mb-3" v-text="$t('links')"></span>
                    <a target="_blank"
                       :href="artisticProfile.instagram_profile_link"
                       v-if="artisticProfile.instagram_profile_link">
                        <b-icon icon="link"></b-icon> Instagram
                    </a>
                    <a target="_blank"
                       :href="artisticProfile.facebook_profile_link"
                       v-if="artisticProfile.facebook_profile_link">
                        <b-icon icon="link"></b-icon> Facebook
                    </a>
                    <a target="_blank"
                       v-for="link in artisticProfile.links" :key="'profile-' + artisticProfile.id + '-' + link.text"
                       :href="link.href">
                        <b-icon icon="link"></b-icon>
                        {{ link.text ? link.text : link.href }}
                    </a>
                </div>

                <!--DESCRIPTION-->
                <div class="block">
                    <span class="heading mb-3" v-text="$t('description')"></span>
                    {{ artisticProfile.description }}
                </div>

                <!--CITY-->
                <div class="block" v-if="artisticProfile.city">
                    <span class="heading mb-3" v-text="$t('city')"></span>
                    <b-icon icon="map-marker-alt"></b-icon> {{ artisticProfile.city.name}}
                </div>

                <!--INSTRUMENTS-->
                <div class="block">
                    <span class="heading mb-3" v-text="$t('instruments')"></span>
                    <b-taglist class="is-small">
                        <b-tag v-for="instrument in artisticProfile.instruments"
                               type="is-secondary"
                               :key="'instrument-' + instrument.id">{{ $t(instrument.name) }}</b-tag>
                    </b-taglist>
                </div>

                <!--STYLISTIC OPTIONS-->
                <div class="block">
                    <span class="heading mb-3" v-text="$t('styles')"></span>
                    <b-taglist>
                        <b-tag v-for="stylisticOption in artisticProfile.stylistic_options"
                               :key="'stylistic-option-' + stylisticOption.id"
                               type="is-secondary">
                            {{ $t(stylisticOption.name) }}
                        </b-tag>
                    </b-taglist>
                </div>

                <!-- FEATURES -->

                <div class="block" v-if="artisticProfile.features && artisticProfile.features.length">
                    <span class="heading mb-3" v-text="$t('features')"></span>
                    <b-taglist>
                        <b-tag v-for="feature in artisticProfile.features"
                               :key="`artistic-profile-${artisticProfile.id}-feature-${feature.id}`"
                               type="is-secondary">
                            {{ $t(feature.type) }}
                        </b-tag>
                    </b-taglist>
                </div>

            </b-collapse>

            <!--PROFILE DETAILS VISIBILITY TOGGLE-->
            <div class="has-text-centered block">
                <b-button type="is-text" size="is-small" @click="showDetails = !showDetails">{{ showDetails ? $t('hide details') :$t('details')}}</b-button>
            </div>

        </div>

        <div class="card-footer">
            <a class="card-footer-item" @click="removeFromCollection">{{$t('remove')}}</a>
        </div>

    </div>
</template>

<script>
import {REMOVE_ARTISTIC_PROFILE_FROM_FAVORITE_COLLECTION} from "@/store/modules/artisticProfiles/actions-types";
import {mapActions, mapMutations} from "vuex";
import confirmationMessageMixin from "@/mixins/confirmationMessageMixin";
import {REMOVE_PROFILE_FROM_FAVORITE_COLLECTION} from "@/store/modules/artisticProfiles/mutation-types";

export default {
    name: "ArtisticProfileCollectionListItem",
    props: {
        artisticProfile: Object,
    },
    data(){
        return {
            showDetails: false,
        };
    },
    methods: {
        ...mapActions('artisticProfiles', [
            REMOVE_ARTISTIC_PROFILE_FROM_FAVORITE_COLLECTION,
        ]),
        ...mapMutations('artisticProfiles', [REMOVE_PROFILE_FROM_FAVORITE_COLLECTION]),
        async removeFromCollection(){
            let {result} = await this.$_p_confirmAction(this.$t('Remove profile from favorites?'));
            if (result){
                this[REMOVE_ARTISTIC_PROFILE_FROM_FAVORITE_COLLECTION](this.artisticProfile.id)
                    .then(res => {
                        if (200 === res.status){
                            this.$buefy.toast.open(this.$t('Artistic profiles removed'));
                            this[REMOVE_PROFILE_FROM_FAVORITE_COLLECTION](this.artisticProfile.id);
                        }
                    });
            }
        },
    },
    computed: {
        profileHasLinks(){
            return this.artisticProfile.instagram_profile_link
                || this.artisticProfile.facebook_profile_link
                || ( this.artisticProfile.links && this.artisticProfile.links.length);
        },
    },
    mixins: [confirmationMessageMixin,]
};
</script>

<style scoped>

</style>