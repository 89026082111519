<template>
    <div >

        <organizer-dashboard-navigation />

        <artistic-profile-collection-list-filter />

        <div v-if="favoriteArtisticProfiles.length">

            <artistic-profile-collection-list-item v-for="profile in favoriteArtisticProfiles"
                                                   :key="profile.id"
                                                   :artistic-profile="profile" />

            <laravel-pagination :pagination-meta="paginationMeta" @change="getProfiles" />
        </div>
        <div v-else class="mt-5 has-text-centered">
            <h3 class="title is-3">{{$t('No projects have been added to the collection')}}</h3>
        </div>


    </div>
</template>

<script>
    import OrganizerDashboardNavigation from "@/components/Organizer/DashboardNavigation";
    import {GET_FAVORITE_ARTISTIC_PROFILES_COLLECTION} from "@/store/modules/artisticProfiles/actions-types";
    import {mapActions, mapMutations} from "vuex";
    import ArtisticProfileCollectionListItem from "@/components/ArtisticProfilesCollection/ArtisticProfileCollectionListItem";
    import {SET_FAVORITE_ARTISTIC_PROFILES} from "@/store/modules/artisticProfiles/mutation-types";
    import LaravelPagination from "@/components/LaravelPagination";
    import ArtisticProfileCollectionListFilter
        from "@/components/ArtisticProfilesCollection/ArtisticProfileCollectionListFilter";
    import store from '@/store/index';

    export default {
        name: "OrganizerArtisticProfilesCollection",
        title(){ return this.$t('Artistic profiles collection');},
        components: {
            ArtisticProfileCollectionListFilter,
            LaravelPagination, ArtisticProfileCollectionListItem, OrganizerDashboardNavigation},
        data(){
            return {
                paginationMeta: {},
            };
        },
        methods: {
            ...mapMutations('artisticProfiles', [
                SET_FAVORITE_ARTISTIC_PROFILES,
            ]),
            ...mapActions('artisticProfiles', [
                GET_FAVORITE_ARTISTIC_PROFILES_COLLECTION,
            ]),
            getProfiles(page = 1){
              this.$Progress.start();
              this[GET_FAVORITE_ARTISTIC_PROFILES_COLLECTION]({params: {page}})
                    .then((res) => {
                        this.paginationMeta = res.data.meta;
                        this.$Progress.finish();
                    }).catch(() => {
                    this.$Progress.fail();
                });
            }
        },
        computed: {
            favoriteArtisticProfiles(){
                return this.$store.state.artisticProfiles.favoriteArtisticProfiles;
            },
        },
      created() {
        this.$Progress.start();
        store.dispatch('artisticProfiles/' + GET_FAVORITE_ARTISTIC_PROFILES_COLLECTION, {params: {page:1}})
            .then(res => {
                this.paginationMeta = res.data.meta;
                this.$Progress.finish();
            }).catch(() => {this.$Progress.fail();});

      }
    };
</script>

<style scoped>

</style>